(function () {
    'use strict';

    angular
        .module('eltazkaraApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = [
        '$scope',
        '$rootScope',
        'Home',
        '$window',
        'User'
    ];

    function HomeController($scope, $rootScope, Home, $window, User) {
        var vm = this;
        vm.featuredEvents = [];
        vm.CategoriesEvents = [];
        vm.PopularVenue = {};
        vm.VenuePerformances = [];
        vm.TrendingToday = [];
        vm.PopularPerformers = [];
        vm.clientWidth = $rootScope.clientWidth;
        
        vm.initCarousel = function () {
            vm.carouselInitializing = true;

            setTimeout(function () {
                if (vm.carouselInitialized) {
                    $('.featuredEventsCarouselFeaturedUnits').slick('unslick');
                    $('.featuredEventsCarouselNavUnits').slick('unslick');
                }

                $('.featuredEventsCarouselFeaturedUnits').slick({
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    asNavFor: '.featuredEventsCarouselNavUnits',
                    autoplay: true,
                    autoplaySpeed: 5000,
                    responsive: [
                        {
                            breakpoint: 1200,
                            settings: {
                                autoplay: false
                            }
                        }
                    ]
                });

                $('.featuredEventsCarouselNavUnits').slick({
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    asNavFor: '.featuredEventsCarouselFeaturedUnits',
                    dots: false,
                    centerMode: false,
                    focusOnSelect: true,
                    responsive: [
                        {
                            breakpoint: 1000,
                            settings: {
                                slidesToShow: 4,
                                slidesToScroll: 1,
                                arrows: false,
                            }
                        },
                        {
                            breakpoint: 860,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 1,
                                arrows: false,
                            }
                        }
                    ]
                });

                console.log('done initializing')
                vm.carouselInitialized = true;
                
                $scope.$apply(function() { vm.carouselInitializing = false; });

                vm.clientWidth = $rootScope.clientWidth;
            });
        };

        vm.getFeaturedEvents = function () {
            Home.getFeaturedEvents({})
                .$promise.then(function (data) {
                    //console.log(data);
                    if (data && data.length > 0) {
                        vm.featuredEvents = data;
                    }
                    else {
                        vm.NoFeatured = true;
                    }
                }, function (error) { })
        };

        vm.getFeaturedCategoriesEvents = function () {
            Home.getFeaturedCategoriesEvents({})
                .$promise.then(function (data) {
                    //console.log(data);
                    if (data && data.length > 0) {
                        vm.CategoriesEvents = data;
                    }
                }, function (error) { })
        };

        vm.getPopularVenue = function () {
            Home.getPopularVenue({})
                .$promise.then(function (data) {
                    ////console.log(data);
                    if (data && data.Venue && data.VenuePerformances) {
                        vm.PopularVenue = data.Venue;
                        for (var i = 0; i < data.VenuePerformances.length; i++) {
                            vm.VenuePerformances.push(data.VenuePerformances[i]);
                        }
                    }
                }, function (error) { })
        };

        vm.getTrendingToday = function () {
            Home.getTrendingToday({})
                .$promise.then(function (data) {
                    if (data && data.length > 0) {
                        vm.TrendingToday = data;
                    }
                    else {
                        vm.NoTrending = true;
                    }
                }, function (error) { })
        };

        vm.getPopularPerformers = function () {
            Home.getPopularPerformers({})
                .$promise.then(function (data) {
                    if (data && data.length > 0) {
                        vm.PopularPerformers = data;
                    }
                }, function (error) { })
        };

        vm.getFeaturedEvents();
        vm.getFeaturedCategoriesEvents();
        vm.getTrendingToday();
        vm.getPopularVenue();
        vm.getPopularPerformers();

        vm.trySell = function (route, id) {
            var _route = route || 'performance';

            if ($rootScope.User && $rootScope.User.Email) {
                User.IsUserBlockedOrDeleted({ "UserId": $rootScope.User.UserId })
                    .$promise.then(function (data) {
                        if (data.status == "true") {
                            location.href = "#/" + _route + "/" + id;
                        }
                        else {
                            location.href = "#/";
                            $rootScope.PopUpTitle = "Blocked User";
                            $rootScope.PopUpDescription = "You don't have access please contact with Admin";
                            $rootScope.showPopup = true;
                        }
                    }, function (error) { })
            }
            else {
                vm.toggleLoginPanel();
            }
        };

        vm.toggleLoginPanel = function () {
            $window.scrollTo(0, 0);
            $rootScope.loginPanelActive = !$rootScope.loginPanelActive;
            document.getElementById("userLoginEmail").value = "";
            document.getElementById("userLoginPassword").value = "";
            document.getElementById("userLoginRemember").checked = false;
            $rootScope.userLoginError = false;
            vm.User = {};
            vm.errors = {};
        };

        $rootScope.$on('app:loginPanelToggled', function() {
            if (!$rootScope.loginPanelActive) {
                vm.initCarousel();
            }            
        });
    }
})();
