(function () {
    "use strict";

    angular
        .module("eltazkaraApp")
        .controller("PaymentController", PaymentController);

    PaymentController.$inject = [
        "$rootScope",
        "PaymentService",
        "$location",
        "$state",
        "$window",
        "$stateParams",
        "userInfo",
    ];

    function PaymentController(
        $rootScope,
        PaymentService,
        $location,
        $state,
        $window,
        $stateParams,
        userInfo
    ) {
        var vm = this;
        vm.id = $stateParams.id;
        vm.stateParams = $state.params;
        vm.proceedClicked = false;
        vm.regex = { postalCode: /^[1-9]\d*$/ };
        vm.isPaymentSelected = false;
        vm.paymentMethod;
        vm.isTermsAgreed = false;
        vm.order = [];
        vm.orderLoading = false;
        vm.isLoggedIn = false;
        vm.userCountry = "";
        vm.userCity = "";
        vm.userAddress = "";
        vm.userPostalCode = "";
        vm.isSold = false;
        vm.DeliveryCities = [];
        vm.DeliveryCityId;
        vm.SelectedCourier;
        vm.Couriers = [];
        vm.CourierId;

        vm.initPage = function () {
            if ($rootScope.User && $rootScope.User.Email) vm.isLoggedIn = true;

            if (vm.stateParams.ticket != null) {
                vm.ticketGroupId = vm.stateParams.ticket.TicketGroupId;
                vm.ticketsNumber = vm.stateParams.ticket.SelectedQuantity;
            } else location.href = "#/performance/" + vm.id + "";

            if ($rootScope.User) {
                vm.userName = $rootScope.User.Name;
                vm.userEmail = $rootScope.User.Email;
                vm.userPhone = $rootScope.User.MobileNumber;
            }
            vm.initDeliveryCities();
            vm.initCouriers();
            vm.reviewOrder();
        };

        vm.initDeliveryCities = function () {
            PaymentService.getDeliveryCities().$promise.then(function (data) {
                vm.DeliveryCities = data;
            });
        };

        vm.initCouriers = function () {
            PaymentService.getCouriers().$promise.then(function (data) {
                vm.Couriers = data;
                vm.SelectedCourier = data[0];
            });
        };

        vm.reviewOrder = function () {
            if ($rootScope.User) {
                vm.orderLoading = true;
                PaymentService.reviewOrder({
                    UserId: $rootScope.User.UserId,
                    TicketGroupId: vm.ticketGroupId,
                    TicketsNumber: vm.ticketsNumber,
                }).$promise.then(
                    function (data) {
                        vm.orderLoading = false;
                        if (data) {
                            vm.order = data;
                            if (!vm.order.IsPhoneVerified) {
                                userInfo.sendData(
                                    "#/performance/" + vm.order.PerformanceId
                                );
                            }
                        }
                    },
                    function (error) {
                        vm.orderLoading = false;
                    }
                );
            }
        };

        vm.initPage();

        vm.setPaymentMethod = function (mehtod) {
            vm.isPaymentSelected = true;
            vm.paymentMethod = mehtod;
        };

        vm.verifyPhone = function (mehtod) {
            vm.isPaymentSelected = true;
            vm.paymentMethod = mehtod;
        };

        vm.proceedPayment = function () {
            if (!vm.proceedClicked && vm.isPaymentSelected) {
                vm.proceedClicked = true;

                var notes =
                    vm.order.UserDeliveryNote != null
                        ? vm.order.UserDeliveryNote
                        : null;

                PaymentService.generateOrder({
                    TicketGroupId: vm.ticketGroupId,
                    TicketsNumber: vm.ticketsNumber,
                    PaymentMethod: vm.paymentMethod,
                    City: vm.order.UserCity,
                    Address: vm.order.UserAddress,
                    PostalCode: vm.order.UserPostalCode,
                    DeliveryNotes: notes,
                    DeliveryCityId: vm.DeliveryCityId,
                    CourierId: vm.SelectedCourier.CourierId,
                }).$promise.then(
                    function (data) {
                        if (data) {
                            if (data.message == "ZalFol") {
                                $state.go(
                                    "orderPayment",
                                    { orderRef: data.orderRef },
                                    { reload: "orderPayment" }
                                );

                                vm.orderRef = data.orderRef;
                            } else if (data.message == "TicketOwner") {
                                vm.isTicketOwner = true;
                            } else if (data.message == "TicketsSold") {
                                vm.isSold = true;
                            } else if (data.message == "LimitExceeded") {
                                vm.limitExceeded = true;
                            }
                        }
                    },
                    function (error) {
                        console.log(error);
                        $window.reload();
                    }
                ).finally(function () {
                    vm.proceedClicked = false;
                });
            }
        };

        vm.printt = function () {
            console.log(vm.SelectedCourier);
        };

        vm.toggleLoginPanel = function () {
            $window.scrollTo(0, 0);
            $rootScope.loginPanelActive = !$rootScope.loginPanelActive;
            document.getElementById("userLoginEmail").value = "";
            document.getElementById("userLoginPassword").value = "";
            document.getElementById("userLoginRemember").checked = false;
            $rootScope.userLoginError = false;
            vm.User = {};
            vm.errors = {};
        };

        vm.returnToPerformance = function () {
            location.href = "#/performance/" + vm.id + "";
        };
    }
})();
