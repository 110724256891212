(function () {
    'use strict';

    angular
        .module('eltazkaraApp')
        .factory('hubFactory', ['$rootScope', 'Hub', function ($rootScope, Hub) {
            var hub = new Hub('elTazkaraHub', {
                listeners: {
                    'SendOrderSuccessfullyPaid': function (orderReference) {
                        console.log(orderReference);
                        $rootScope.$emit('hub:orderPaidSuccessfully', orderReference);
                    }
                },
                errorHandler: function (error) { console.log('%c' + error, 'color: #fff'); },
                hubDisconnected: function () {
                    if (hub.connection.lastError) {
                        hub.connection.start();
                    }
                },
                logging: true,
                rootPath: window.backendUrl.replace('api/', 'signalr'),
                stateChanged: function (state) {
                    switch (state.newState) {
                        case $.signalR.connectionState.connecting:
                            console.log('Connecting to SignalR hub...');
                            break;
                        case $.signalR.connectionState.connected:
                            console.log('Connected to SignalR hub.');
                            break;
                        case $.signalR.connectionState.reconnecting:
                            console.log('Reconnecting to SignalR hub...');
                            break;
                        case $.signalR.connectionState.disconnected:
                            console.log('Disconnected from SignalR hub.');
                            hub.connection.start();
                            break;
                    }
                }
            });

            return hub;
        }]);
})()
