(function() {
    'use strict';

    angular
        .module('eltazkaraApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider, $rootScope) {
        $stateProvider.state('payment', {
            parent: 'app',
            url: '/payment/:id',
            cache: false,
            params: { ticket: null, performanceDetails: null },
            views: {
                'content@': {
                    templateUrl: 'components/payments/payment.html',
                    controller: 'PaymentController',
                    controllerAs: 'vm'
                }
            }
        }).state('orderPayment', {
            parent: 'app',
            cache: false,
            url: '/payment_details/:orderRef',
            params: { paymentSuccess: false },
            views: {
                'content@': {
                    templateUrl: 'components/payments/paymentDetails.html',
                    controller: 'PaymentDetailsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: [
                    '$translate',
                    '$translatePartialLoader',
                    function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                    }
                ]
            }
        }).state('paymentSuccess', {
            parent: 'app',
            cache: false,
            url: '/payment_success/:orderRef',
            // params: {orderRef: null, performanceDetails: null},
            views: {
                'content@': {
                    templateUrl: 'components/payments/paymentSuccessPopup.html',
                    controller: 'PaymentSuccessPopupController',
                    controllerAs: 'vm'
                }
            }
        }).state('paymentFailed', {
            parent: 'app',
            cache: false,
            url: '/payment_failed/:orderRef',
            // params: {orderRef: null, performanceDetails: null},
            views: {
                'content@': {
                    templateUrl: 'components/payments/paymentFailedPopup.html',
                    controller: 'PaymentFailedPopupController',
                    controllerAs: 'vm'
                }
            }
        }).state('orderDetails', {
            parent: 'app',
            url: '/order_details',
            cache: false,
            views: {
                'content@': {
                    templateUrl: 'components/payments/paymentSuccess.html',
                    controller: 'PaymentSuccessController',
                    controllerAs: 'vm'
                }
            }
        }).state('paymentResult', {
            parent: 'app',
            cache: false,
            url: '/payment_result',
            views: {
                'content@': {
                    template: null,
                    controller: 'PaymentResultController',
                    controllerAs: 'vm'
                }
            },
            onEnter: ['$rootScope', function ($rootScope) { $rootScope.noContent = true; }]
        }).state('sell', {
            parent: 'app',
            url: '/performance/:id/sell',
            params: { ticket: null, performanceDetails: null },
            views: {
                'content@': {
                    templateUrl: 'components/payments/sell.html',
                    controller: 'SellController',
                    controllerAs: 'vm'
                }
            }
        });
    }
})();