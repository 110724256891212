(function () {
    "use strict";

    angular
        .module("eltazkaraApp")
        .controller("PaymentResultController", PaymentResultController);

    PaymentResultController.$inject = [
        "$state",
        "$location",
        "PaymentService",
        "utilsService",
    ];

    function PaymentResultController(
        $state,
        $location,
        PaymentService,
        utilsService
    ) {
        var vm = this;

        vm.handlePaymentResult = function () {
            var queryStr = utilsService.getQueryParamsStr();
            var queryParams = $location.search();

            if (queryStr) {
                vm.orderRef = queryParams.merchantOrderId;

                return PaymentService.handlePaymentRedirection({
                    query: queryStr,
                }).$promise.then(
                    function (res) {
                        vm.navigateToOrderDetails(res && res.Status === 2);
                    },
                    function (error) {
                        console.log(error);
                        vm.navigateToOrderDetails();
                    }
                );
            }

            $state.go("home");
        };

        vm.init = function () {
            vm.handlePaymentResult();
        };

        vm.navigateToOrderDetails = function (success) {
            var params = {
                orderRef: vm.orderRef,
            };

            if (success) {
                params.paymentSuccess = true;
            }

            $state.go("orderPayment", params);
        };

        vm.init();
    }
})();
