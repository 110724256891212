(function () {
    "use strict";

    angular.module("eltazkaraApp").factory("utilsService", utils);

    function utils() {
        return {
            getQueryParams: function () {
                var queryString = window.location.hash;
                var params = {};
                var regex = /[?&]([^=]+)=([^&]*)/g;
                var match;

                while ((match = regex.exec(queryString)) !== null) {
                    params[match[1]] = match[2];
                }

                return params;
            },
            getQueryParamsStr: function () {
                var queryString = window.location.hash;
                var str = "";
                var idx = 0;
                var regex = /[?&]([^=]+)=([^&]*)/g;
                var match;

                while ((match = regex.exec(queryString)) !== null) {
                    if (idx > 0) {
                        str += "&";
                    }

                    str += match[1] + "=" + match[2];
                    idx++;
                }

                return str !== "" ? str : undefined;
            },
        };
    }
})();
